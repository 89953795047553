import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { clsx } from 'clsx';
import { ComponentProps, useEffect } from 'react';
import { Decimal } from 'decimal.js-light';
import { Form, Formik, useField } from 'formik';
import { object, string } from 'yup';
import { orderBy } from 'lodash-es';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DATE_FORMATS, formatDate } from 'util/dates';
import { formatDollars } from '../../util/currency';
import { getNestedErrorMessages } from 'util/forms';
import { launchSurvey } from '../../services/backend/surveys';
import {
  Question,
  Survey,
  SurveyPayment,
  SurveyVariable,
  SurveyWave,
} from '../../types/domainModels';
import { showErrorMessage } from '../../util/notifications';
import { surveyQueries } from 'hooks/backend/surveys';
import { useAuth } from 'contexts/auth';
import { useHasRole } from 'hooks/users';
import { useSubmitValidation } from '../../hooks/forms';
import { useSurveyOrganization } from '../../hooks/backend/organizations';

import ButtonLoading from 'components/common/forms/ButtonLoading';
import Checkbox from 'components/common/forms/Checkbox';
import ErrorDisplay from 'components/common/ErrorDisplay';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormGroupOld from '../common/forms/FormGroup';
import FormInput from '../common/forms/FormInput';
import Hyperlink from '../common/Hyperlink';
import Icon from 'components/common/Icon';
import IconBackground from '../common/icons/IconBackground';
import ReviewSurveySummary from '../surveyEdit/ReviewSurveySummary';
import SkeletonSurveyCard from './SkeletonSurveyCard';
import SurveyStepStickyHeader from './SurveyStepStickyHeader';
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabWithAlert,
} from 'components/common/Tabs';

interface ReviewFormData {
  checkout: {
    email: string;
    paidFor: boolean;
    paymentMethod: 'creditCard' | 'invoice';
    purchaseOrder: string;
  };
}

function requiredForCreditCard(
  paidFor: ReviewFormData['checkout']['paidFor'],
  paymentMethod: ReviewFormData['checkout']['paymentMethod'],
) {
  return !paidFor && paymentMethod === 'creditCard';
}

const ReviewSchema = object().shape({
  checkout: object().shape({
    email: string().when(['paidFor', 'paymentMethod'], {
      is: requiredForCreditCard,
      then: (schema) =>
        schema
          .email('Please provide a valid email.')
          .required('Please provide your email.'),
    }),
  }),
});

const ReviewStep = ({
  survey,
  ...rest
}: Omit<
  ComponentProps<typeof ReviewStepLoaded>,
  'amountOwed' | 'payments'
>) => {
  const { amountOwed, isLoading, payments } = useAmountOwed({
    surveyId: survey.id,
    waveId: survey.waveId,
  });

  if (isLoading) {
    return <SkeletonSurveyCard />;
  } else if (amountOwed === null) {
    return (
      <div className="py-6">
        <ErrorDisplay message="Could not determine the amount owed for this survey. Please refresh the page." />
      </div>
    );
  }

  return (
    <ReviewStepLoaded
      {...rest}
      amountOwed={amountOwed}
      payments={payments}
      survey={survey}
    />
  );
};

export default ReviewStep;

const ReviewStepLoaded = ({
  amountOwed,
  onHasError,
  onStepCompleted,
  payments,
  questions,
  survey,
  surveyId,
  surveyVariables,
  waves,
}: {
  amountOwed: Decimal;
  onHasError(): void;
  onStepCompleted(): void;
  payments: SurveyPayment[];
  questions: Question[];
  survey: Survey;
  surveyId: number;
  surveyVariables: SurveyVariable[];
  waves: SurveyWave[];
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const isAdmin = useHasRole('admin');

  const surveyOrg = useSurveyOrganization({ survey });
  const canChoosePaymentMethod = isAdmin || !!surveyOrg?.useInvoice;

  const initialValues = {
    checkout: {
      email: user?.email ?? '',
      paidFor: amountOwed.lte(0),
      paymentMethod: canChoosePaymentMethod ? 'invoice' : 'creditCard',
      purchaseOrder: '',
    },
  } satisfies ReviewFormData;

  const { isPending: isLaunching, mutate: payAndLaunch } = useMutation({
    mutationFn: async (data: ReviewFormData) => {
      if (amountOwed.lte(0)) {
        return launchSurvey({
          data: { amountOwed: amountOwed.toNumber() },
          surveyId,
        });
      } else if (data.checkout.paymentMethod === 'creditCard') {
        const cardElement = elements?.getElement('card');
        if (!stripe || !cardElement) {
          throw new Error(
            'Could not load credit card payment processor. Please refresh the page and try again.',
          );
        }

        const { error, token } = await stripe.createToken(cardElement);
        if (error) {
          throw new Error(error.message);
        }

        return launchSurvey({
          data: {
            amountOwed: amountOwed.toNumber(),
            payment: {
              email: data.checkout.email,
              stripeToken: token?.id || '',
              type: 'creditCard',
            },
          },
          surveyId,
        });
      }

      return launchSurvey({
        data: {
          amountOwed: amountOwed.toNumber(),
          payment: {
            purchaseOrder: data.checkout.purchaseOrder,
            type: 'invoice',
          },
        },
        surveyId,
      });
    },
    onError: (err) => {
      queryClient.invalidateQueries(
        surveyQueries.survey({ surveyId: survey.id }),
      );

      showErrorMessage({ err });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        surveyQueries.survey({ surveyId: survey.id }),
      );
      onStepCompleted();
    },
  });

  return (
    <Formik<ReviewFormData>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(formData) => {
        payAndLaunch(formData);
      }}
      validateOnChange={false}
      validationSchema={ReviewSchema}
    >
      <Form className="h-full">
        <ReviewForm
          amountOwed={amountOwed}
          canChoosePaymentMethod={canChoosePaymentMethod}
          isLoading={isLaunching}
          onHasError={onHasError}
          payments={payments}
          questions={questions}
          survey={survey}
          surveyVariables={surveyVariables}
          waves={waves}
        />
      </Form>
    </Formik>
  );
};

const ReviewForm = ({
  amountOwed,
  canChoosePaymentMethod,
  isLoading,
  onHasError,
  payments,
  questions,
  survey,
  surveyVariables,
  waves,
}: {
  amountOwed: Decimal;
  canChoosePaymentMethod: boolean;
  isLoading: boolean;
  onHasError(): void;
  payments: SurveyPayment[];
  questions: Question[];
  survey: Survey;
  surveyVariables: SurveyVariable[];
  waves: SurveyWave[];
}) => {
  const { errors, onClickSubmit } = useSubmitValidation<ReviewFormData>({
    isSaving: isLoading,
    onHasError,
  });

  const submitText =
    amountOwed.lte(0) || survey.isBringYourOwnAudience
      ? 'Launch'
      : 'Pay & Launch';

  const nestedErrors = errors ? getNestedErrorMessages(errors) : [];

  const tabs = survey.isBringYourOwnAudience
    ? []
    : [
        <Tab key={0}>Summary</Tab>,
        <TabWithAlert key={1} hasAlert={!!errors?.checkout}>
          Checkout
        </TabWithAlert>,
      ];

  return (
    <div>
      <TabGroup>
        <SurveyStepStickyHeader>
          {tabs.length > 0 ? (
            <div className="grow -mb-2">
              <TabList size="sm">{tabs}</TabList>
            </div>
          ) : (
            <h2 className="text-base font-semibold">Summary</h2>
          )}

          <ButtonLoading
            disabled={survey.statusId === 1 || nestedErrors.length > 0}
            hierarchy="primary"
            isLoading={isLoading}
            onClick={onClickSubmit}
            size="sm"
            // This can't currently be a submit button since we handle the form submission
            // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
            type="button"
          >
            {submitText}
          </ButtonLoading>
        </SurveyStepStickyHeader>

        {nestedErrors.length > 0 && (
          <div className="mb-8">
            <FormErrorsAlert actionWord="launching" errors={nestedErrors} />
          </div>
        )}

        <TabPanels>
          <TabPanel>
            <ReviewSurveySummary
              questions={questions}
              survey={survey}
              surveyVariables={surveyVariables}
            />
          </TabPanel>
          {!survey.isBringYourOwnAudience && (
            <TabPanel>
              <div className="space-y-8">
                <Checkout
                  amountOwed={amountOwed}
                  canChoosePaymentMethod={canChoosePaymentMethod}
                />
                {payments.length > 0 ? (
                  <Payments payments={payments} waves={waves} />
                ) : null}
              </div>
            </TabPanel>
          )}
        </TabPanels>
      </TabGroup>
    </div>
  );
};

const Payments = ({
  payments,
  waves,
}: {
  payments: SurveyPayment[];
  waves: SurveyWave[];
}) => {
  const hasMultipleWaves = waves.length > 1;
  const orderedPayments = orderBy(payments, (p) => p.createdAt, 'desc');

  const gridColsClass = clsx({
    'grid-cols-5': hasMultipleWaves,
    'grid-cols-4': !hasMultipleWaves,
  });

  return (
    <div>
      <h2 className="font-medium">Payments</h2>

      <div
        className={clsx(
          'grid gap-2 bg-white py-2 border-b border-gray-300 text-gray-600 rounded-t-lg',
          gridColsClass,
        )}
      >
        <div>Date</div>
        <div>Name</div>
        <div>Amount</div>
        {hasMultipleWaves ? <div>Wave</div> : null}
        <div>Type</div>
      </div>

      {orderedPayments.map((payment) => {
        const wave = waves.find((w) => w.id === payment.waveId);

        return (
          <div
            key={payment.id}
            className={clsx('grid gap-2 py-2', gridColsClass)}
          >
            <div>
              {formatDate(payment.createdAt, { format: DATE_FORMATS.DATETIME })}
            </div>
            <div>{payment.user.name}</div>
            <div>{formatDollars(payment.amount)}</div>
            {hasMultipleWaves ? <div>{wave?.title}</div> : null}
            <div>
              {payment.type === 'creditCard' ? 'Credit Card' : 'Invoice'}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Checkout = ({
  amountOwed,
  canChoosePaymentMethod,
}: {
  amountOwed: Decimal;
  canChoosePaymentMethod: boolean;
}) => {
  const [{ value: paymentMethod }, , paymentMethodHelpers] = useField<
    ReviewFormData['checkout']['paymentMethod']
  >('checkout.paymentMethod');

  return (
    <div>
      {amountOwed.lte(0) ? (
        <div className="flex items-center mt-4 space-x-2 text-sm">
          <IconBackground title="Paid For">
            <div className="w-4 h-4 text-primary-d-600">
              <Icon id="check" />
            </div>
          </IconBackground>
          <span>This survey has been paid for.</span>
        </div>
      ) : (
        <div className="space-y-6">
          {canChoosePaymentMethod && (
            <div className="flex space-x-6">
              <Checkbox
                checked={paymentMethod === 'invoice'}
                label="Pay via Invoice"
                name="checkout.paymentMethod"
                onChange={(event) => {
                  if (event.currentTarget.checked) {
                    paymentMethodHelpers.setValue('invoice');
                  }
                }}
                radio={true}
              />
              <Checkbox
                checked={paymentMethod === 'creditCard'}
                label="Pay via Credit Card"
                name="checkout.paymentMethod"
                onChange={(event) => {
                  if (event.currentTarget.checked) {
                    paymentMethodHelpers.setValue('creditCard');
                  }
                }}
                radio={true}
              />
            </div>
          )}

          <div className="mt-2 space-y-4">
            {paymentMethod === 'invoice' ? (
              <InvoiceForm cost={amountOwed} />
            ) : (
              <CreditCardForm cost={amountOwed} />
            )}

            <p className="text-dark-grey text-xs italic">
              By launching this survey you agree to Glass's{' '}
              <Hyperlink href="https://www.useglass.com/terms">
                terms of service
              </Hyperlink>
              .
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const CreditCardForm = ({ cost }: { cost: Decimal }): JSX.Element => {
  return (
    <div className="space-y-4">
      <p className="text-sm">
        Your credit card will be charged{' '}
        <strong className="font-bold">{formatDollars(cost.toNumber())}</strong>.
      </p>
      <FormInput
        id="email"
        label="Receipt Email"
        labelFor="email"
        name="checkout.email"
        size="md"
        type="email"
      />
      <FormGroupOld label="Card Info" labelFor="stripe-card-element">
        <div className="w-full rounded-lg border border-gray-d-300 shadow-sm py-2 px-3">
          <CardElement id="stripe-card-element" />
        </div>
      </FormGroupOld>
    </div>
  );
};

const InvoiceForm = ({ cost }: { cost: Decimal }): JSX.Element => {
  return (
    <div className="space-y-4">
      <p className="text-sm">
        An invoice will be submitted for{' '}
        <strong className="font-bold">{formatDollars(cost.toNumber())}</strong>.
      </p>
      <FormInput
        id="purchaseOrder"
        label="Purchase Order"
        labelFor="purchaseOrder"
        name="checkout.purchaseOrder"
        placeholder="(Optional)"
        size="md"
        type="text"
      />
    </div>
  );
};

function useAmountOwed({
  surveyId,
  waveId,
}: {
  surveyId: number;
  waveId: number;
}) {
  const queryClient = useQueryClient();

  const { data: getCostResult, isLoading: isLoadingCost } = useQuery(
    surveyQueries.cost({ surveyId }),
  );
  const cost = getCostResult?.cost;

  const { data: payments = [], isLoading: isLoadingPayments } = useQuery(
    surveyQueries.payments({ surveyId }),
  );

  // We invalidate the survey on mount because the cost of the survey might have changed since the
  // last time the survey was fetched. We want to ensure we have the most up-to-date value.
  useEffect(() => {
    queryClient.invalidateQueries(surveyQueries.cost({ surveyId }));
  }, [surveyId, queryClient]);

  let totalPaymentsForCurrentWave = 0;
  for (const payment of payments) {
    if (payment.waveId === waveId) {
      totalPaymentsForCurrentWave += payment.amount;
    }
  }

  const amountOwed = cost
    ? new Decimal(cost).minus(totalPaymentsForCurrentWave)
    : null;

  return {
    amountOwed,
    isLoading: isLoadingCost || isLoadingPayments,
    payments,
  };
}
